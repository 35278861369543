import "./App.css";
import { AppRouter } from "./components/AppRouter";
import { useLocation } from "react-router";
import { sendMetriks } from "./utils/metriks";
// @ts-ignore
import TagManager from "react-gtm-module";
import { useEffect } from "react";
// import { MainPage } from "./pages/MainPage";

function App() {
  const location = useLocation();  
  const tagManagerArgs = {
    gtmId: "GTM-KTZNQKVX",
  };
  sendMetriks('hit', location.pathname);
  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, []);
  return (
    <div>
      <AppRouter />
    </div>
  );
}

export default App;
